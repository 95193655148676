import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractRepository } from '@domain/services/contract/repository/contract.repository';
import { getContractByNameResponse } from '@domain/services/contract/dto/read-contract.dto';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class ContractRepositoryService extends ContractRepository {

  urlApi = `${URL_BACKEND}/management/contract`;

  constructor(private http: HttpClient) {
    super();
  }

  getContractByName(name: string): Observable<getContractByNameResponse> {
    return this.http.get<getContractByNameResponse>(
      `${this.urlApi}/show?name=${name}`
    );
  }

}
