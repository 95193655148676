import { Observable } from 'rxjs';
import {
  UpsertCustomerContractPayload,
  UpsertCustomerContractResponse,
} from '../dto/customer-contract-upsert.dto';
import { ReadContractCustomerResponse } from '../dto/read-contract-customer.dto';

export abstract class CustomerContractRepository {

  abstract showCustomerContract(
    order_id: string
  ): Observable<ReadContractCustomerResponse>;

  abstract upsertCustomerContract(
    payload: UpsertCustomerContractPayload
  ): Observable<UpsertCustomerContractResponse>;
}
