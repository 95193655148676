import { Observable } from 'rxjs';
import {
  getContractByNameResponse,
} from '../dto/read-contract.dto';

export abstract class ContractRepository {
  abstract getContractByName(
    name: string
  ): Observable<getContractByNameResponse>;
}
